import { useEffect, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import { useIsStart } from "@/lib/helpers/template"
import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import { Article } from "./article"
import Button from "@/common/button/button"
import Image from "next/image"
import styles from "./hero.module.css"
import classNames from "classnames"

export default function Hero({ block, settings }) {
  const [hasMounted, setMounted] = useState(false)
  const isMobile = useMediaPredicate("(max-width: 768px)")

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const { innerBlocks } = block
  const {
    articles,
    image,
    video,
    align,
    size,
    opacity,
    mediaType,
    mediaAlign,
    archiveButton,
    hideArchiveButton,
    archiveURL
  } = block.attrs

  const imageSrc = isMobile && image.tall ? image.tall : image.wide
  const videoSrc = isMobile && video.tall ? video.tall : video.wide
  const credit = imageSrc?.credit ?? null
  const hasBackground = imageSrc || videoSrc
  const textColorClass = hasBackground ? "text-white" : "text-black-100"
  const baseBackground = mediaType === "video" ? "" : "bg-summer-green-50"
  const blockGap = articles.length > 0 ? "normal" : block.attrs.blockGap

  return (
    <>
      <Block
        block={block}
        className={classNames(
          styles.hero,
          baseBackground,
          textColorClass,
          size,
          {
            "has-articles": articles.length > 0
          }
        )}
        width="bleed"
        gap={blockGap}>
        {hasMounted && mediaType === "image" && imageSrc && (
          <figure className={styles.background}>
            <Image
              className="object-cover object-center w-full"
              src={imageSrc.url}
              alt={block.attrs.image.wide.alt}
              width={imageSrc.width}
              height={imageSrc.height}
              priority={true}
              placeholder="empty"
              loading="eager"
            />
          </figure>
        )}

        {hasMounted && mediaType === "video" && (
          <div className={classNames(styles.background, "items-" + mediaAlign)}>
            <video
              className={styles.videoOverlay}
              preload="auto"
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}>
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        )}

        {hasBackground && (
          <div className={classNames(styles.overlay, "opacity" + opacity)} />
        )}
        <Breadcrumbs
          hidden={useIsStart() || settings?.hideBreadcrumbs}
          wrapperClassName={styles.breadcrumbs}
        />
        <Block
          block={block}
          className={classNames(styles.wrapper, styles[align])}
          width="full">
          <div className={styles.innerWrapper}>
            <div className={styles.content}>
              <Blocks blocks={innerBlocks} />
            </div>
          </div>
        </Block>
        {credit && <span className="credit">Foto: {credit}</span>}
      </Block>
      {articles.length > 0 && (
        <Block block={block} className={styles.articlesWrapper} width="full">
          <div className={styles.articlesWrapper}>
            <div className={styles.articles}>
              {articles.map((article, index) => (
                <Article
                  key={`article-${index}`}
                  className={styles.article}
                  item={article}
                  showTag={false}
                />
              ))}
              {archiveButton && !hideArchiveButton && (
                <div className="button-wrapper">
                  <Button
                    href={archiveURL}
                    ariaLabel={archiveButton}
                    theme="lime"
                    tagName="a">
                    {archiveButton}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Block>
      )}
    </>
  )
}
